
import {DataField} from '../DataField';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class DataFieldCategoryGen extends Model {
  protected _id: number;
  protected _name: string;
  protected _condVisible: string;
  protected _fields: DataField[];
  protected _orderNr: number;
  protected _hidden: boolean;

  constructor(json: {id: number, name: string, condVisible: string, fields: {id: number, name: string, readonly: boolean, mandatory: boolean, type: number, attributeName: string, orderNr: number, hidden: boolean, optionsAreTemplates: boolean, insertPrevious: boolean, initValue: string, maxCharLength: number, condEnabled: string, condVisible: string, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean}}[]}[], orderNr: number, hidden: boolean}, public _api: IGISApi) {
    super();
    this._id = json.id;
    this._name = json.name;
    this._condVisible = json.condVisible;
    this._fields =  json.fields.map(item => new DataField(item, _api));
    this._orderNr = json.orderNr;
    this._hidden = json.hidden;
    this.onNew();
  }
}
