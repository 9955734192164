import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../../igis-base";
import {DataEntryDlg} from "./DataEntryDialog";
import {COMP_IDS} from "../GUIDefinitions";
import {ExtPanel} from "../../../ext-types/panel/ExtPanel";
import {first} from "rxjs/operators";

export class DataEntryComponent extends Component {
  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {

      const mapPanel = <ExtPanel>Ext.getCmp(COMP_IDS.PANEL_MAP);

      this.app.feature.beginDataEntry$.subscribe((beginDataEntryEvent) => {
        console.log('begin data entry');
        const feature = beginDataEntryEvent.selFeature;
        const dataSet = beginDataEntryEvent.dataSet;

        const dataEntryDlg = new DataEntryDlg(this.app, feature, dataSet, Ext, mapPanel.getHeight() * 0.9,
          "erfassen", (save, fieldValues) => {

            if (!save || !fieldValues) {
              // allow closing of dialog
              return Promise.resolve(true);
            }
            const featureParams = feature.getFeatureIdParams();
            if (!featureParams) {
              console.log('featureParams were null?');
              return Promise.resolve(true); // we don't know what to do, just close dialog
            }

            const params = {
              dataSetId: dataSet.id,
              values: fieldValues
            }

            return this.app.api.addDataEntry({...featureParams, ...params}).then(addDataEntryResult => {
              return addDataEntryResult != null && addDataEntryResult.data != null;
            }).catch(() => {
              return false; // the call failed, do not close the dialog
            });
          });

        dataEntryDlg.show();
      });
    })
  }
}
