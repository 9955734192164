
import {VisualizationColumn} from '../VisualizationColumn';
import {Report} from '../Report';
import {DataSet} from '../DataSet';
import {Attribute} from '../Attribute';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class LayerGen extends Model {
  protected _id: number;
  protected _group: boolean;
  protected _parentId: number;
  protected _defaultVisible: boolean;
  protected _queryable: boolean;
  protected _imageLayer: boolean;
  protected _name: string;
  protected _nameSingular: string;
  protected _orderNr: number;
  protected _geomType: string;
  protected _maxVertices: number;
  protected _reports: Report[];
  protected _addFeatureDataSet: DataSet | undefined;
  protected _availPerms: number[];
  protected _nameAttributes: Attribute[];
  protected _shortInfoAttributes: Attribute[];
  protected _visColumn: VisualizationColumn | undefined;
  protected _mapReport: boolean;

  constructor(json: {id: number, group: boolean, parentId: number, defaultVisible: boolean, queryable: boolean, imageLayer: boolean, name: string, nameSingular: string, orderNr: number, geomType: string, maxVertices: number, reports: {id: number, name: string, category: string, isFeatureSel: boolean, availFilter: {date: boolean, malfunction: boolean, batch: boolean}}[], addFeatureDataSet?: {name: string, id: number, batch: boolean, open: number, fieldCategories: {id: number, name: string, condVisible: string, fields: {id: number, name: string, readonly: boolean, mandatory: boolean, type: number, attributeName: string, orderNr: number, hidden: boolean, optionsAreTemplates: boolean, insertPrevious: boolean, initValue: string, maxCharLength: number, condEnabled: string, condVisible: string, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean}}[]}[], orderNr: number, hidden: boolean}[], layers: {layerId: number, displayOnly: boolean}[]}, availPerms: number[], nameAttributes: {name: string, displayName: string, info: string, unit: string, digits: number, type: number, searchable: boolean, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean}}[]}[], shortInfoAttributes: {name: string, displayName: string, info: string, unit: string, digits: number, type: number, searchable: boolean, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean}}[]}[], visColumn?: {attributeName: string, visOptions: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean}}[]}, mapReport: boolean}, public _api: IGISApi) {
    super();
    this._id = json.id;
    this._group = json.group;
    this._parentId = json.parentId;
    this._defaultVisible = json.defaultVisible;
    this._queryable = json.queryable;
    this._imageLayer = json.imageLayer;
    this._name = json.name;
    this._nameSingular = json.nameSingular;
    this._orderNr = json.orderNr;
    this._geomType = json.geomType;
    this._maxVertices = json.maxVertices;
    this._reports =  json.reports.map(item => new Report(item, _api));
    this._addFeatureDataSet = json.addFeatureDataSet ? new DataSet(json.addFeatureDataSet, _api): undefined;
    this._availPerms = json.availPerms;
    this._nameAttributes =  json.nameAttributes.map(item => new Attribute(item, _api));
    this._shortInfoAttributes =  json.shortInfoAttributes.map(item => new Attribute(item, _api));
    this._visColumn = json.visColumn ? new VisualizationColumn(json.visColumn, _api): undefined;
    this._mapReport = json.mapReport;
    this.onNew();
  }
}
