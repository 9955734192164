/**
 * ExtJS GUI definitions for main application window.
 * Current ExtJS version 7.4
 */
import {IExt} from "../../ext-types/Ext";

declare var Ext: IExt;

export const txtNoReports = 'Keine Berichte verfügbar';
export const txtNoDatasets = 'Keine Aktionen verfügar';

/*
  Icons used for buttons
 */
const pathIconSettings = require('../../icons/settings.png');

export const pathIconPictureDisabled = require('../../icons/mPicture_dis.png');
export const pathIconPictureEnabled = require('../../icons/mPicture.png');

export const pathIconEnterLevelsEnabled = require('../../icons/mEnterLevels.png');
export const pathIconEnterLevelsDisabled = require('../../icons/mEnterLevels_dis.png');

export const pathIconExitLevelsEnabled = require('../../icons/mExit.png');
export const pathIconExitLevelsDisabled = require('../../icons/mExit_dis.png');

export const pathIconPrintMapEnabled = require('../../icons/mActionPrint.png');
export const pathIconPrintMapDisabled = require('../../icons/mActionPrint_dis.png');

export const pathIconPrintReport = require('../../icons/mActionPrintReport.png');
export const pathIconPrintReportDisabled = require('../../icons/mActionPrintReport_dis.png');

export const pathIconAddGeometry = require('../../icons/mActionAddGeometry.png');

export const pathIconDelGeometryEnabled = require('../../icons/mActionDelGeometry.png');
export const pathIconDelGeometryDisabled = require('../../icons/mActionDelGeometry_dis.png');

export const pathIconMoveGeometryEnabled = require('../../icons/mActionMoveGeometry.png');
export const pathIconMoveGeometryDisabled = require('../../icons/mActionMoveGeometry_dis.png');

export const pathIconUploadEnabled = require('../../icons/mUpload.png');
export const pathIconUploadDisabled = require('../../icons/mUpload_dis.png');

const pathIconLogout = require('../../icons/mActionLogout.png');

export const pathIconAddDataEntry = require('../../icons/mActionAddDataEntry.png');
export const pathIconAddDataEntryDisabled = require('../../icons/mActionAddDataEntry_dis.png');

const pathIconStopDataEntry = require('../../icons/mActionStopDataEntry.png');
export const pathIconCloseBatchEnabled = require('../../icons/mActionCloseBatch.png');
export const pathIconCloseBatchDisabled = require('../../icons/mActionCloseBatch_dis.png');

const pathIconHelp = require('../../icons/mActionHelp.png');


/*
  Generate component ids for referencing from handlers, application components, etc.
 */
export const COMP_IDS = {
  STATUS_TEXT: Ext.id(),
  SEARCH: {
    PANEL: Ext.id(),
    BUTTON: Ext.id(),
    TEXTFIELD: Ext.id(),
    RESULT_PANEL: Ext.id(),
    EMPTY_MESSAGE: Ext.id(),
  },
  PAST_DUE: {
    PANEL: Ext.id(),
    RESULT_PANEL: Ext.id(),
    EMPTY_MESSAGE: Ext.id()
  },
  LIST_MALFUNCTION: {
    PANEL: Ext.id(),
    RESULT_PANEL: Ext.id(),
    EMPTY_MESSAGE: Ext.id()
  },
  PRINT_MAP: Ext.id(),
  PRINT_REPORT: Ext.id(),
  PRINT_STATUS_MESSAGE: Ext.id(),
  PRINT_FEATURE_REPORT: Ext.id(),
  GEOMETRY: {
    ADD: Ext.id(),
    MOVE: Ext.id(),
    DEL: Ext.id()
  },
  DATA_ENTRY: {
    START: Ext.id(),
    STOP: Ext.id(),
    OPEN_CNT: Ext.id(),
    CLOSE_BATCH: Ext.id(),
    START_FEATURE: Ext.id()
  },
  STORE: {
    DATASETLAYER: Ext.id()
  },
  LOGOUT: Ext.id(),
  CHANGELOG: Ext.id(),
  FEATURE_WINDOW: Ext.id(),
  SETTINGS: Ext.id(),
  ENTER_LEVELS: Ext.id(),
  FI_PANEL: Ext.id(),
  LAYER_LEVEL_PANEL: Ext.id(),
  LAYER_TREE: Ext.id(),
  LAYER_TREE_SAVE: Ext.id(),
  LAYER_TREE_RESTORE: Ext.id(),
  LEVEL_TREE: Ext.id(),
  PANEL_LEFT: Ext.id(),
  PANEL_CENTER: Ext.id(),
  PANEL_MAP: Ext.id(),
  UPLOAD_BUTTON: Ext.id(),
  ACCORDION_LEFT: Ext.id(),
  SHORT_INFO_PANEL: Ext.id(),
  LEGEND_PANEL: Ext.id()
}

export const viewportItems = [{
  xtype: 'panel',
  layout: 'border',
  items: [{
    xtype: 'panel',
    margins: '3 0 3 3',
    cmargins: '3 3 3 3',
    title: 'Tools',
    width: 260,
    collapsible: true,
    layout: 'vbox',
    region: 'west',
    floatable: false,
    minWidth: 200,
    split: true,
    collapseMode: 'standard',
    id: COMP_IDS.PANEL_LEFT,
    items: [{
      xtype: 'panel',
      layout: 'accordion',
      border: false,
      id: COMP_IDS.ACCORDION_LEFT,
      flex: 0.9,
      width: '100%',
      layoutConfig: {
        titleCollapse: true,
        animate: true,
        activeOnTop: false
      },
      activeItem: 2,
      items: [{
        title: 'Ebenen',
        layout: 'vbox',
        items:
          [{
            xtype: 'treepanel',
            border: false,
            lines: false,
            title: 'Kartenebenen',
            height: '200',
            flex: 1,
            width: '100%',
            autoScroll: true,
            useArrows: true,
            id: COMP_IDS.LAYER_TREE,
            store: {
              root: {
                expanded: false,
                text: '(Projekt wird geladen...)',
                children: []
              }
            },
            tools: [{
              type: 'save',
              tooltip: 'Konfiguration speichern',
              id: COMP_IDS.LAYER_TREE_SAVE
            }, {
              type: 'refresh',
              tooltip: 'Auf Standard zurücksetzen',
              id: COMP_IDS.LAYER_TREE_RESTORE
            }]
          }, {
            xtype: 'gridpanel',
            title: 'Objektebenen',
            store: new Ext.data.ArrayStore({
              autoDestroy: true,
              idIndex: 0,
              fields: [
                'name'
              ]
            }),
            width: '100%',
            id: COMP_IDS.LEVEL_TREE,
            disabled: true,
            hideHeaders: true,
            columns: [{
              text: 'Ebene',
              flex: 1,
              dataIndex: 'name'
            }]
          }]
      }, {
        xtype: 'panel',
        id: COMP_IDS.LEGEND_PANEL,
        title: 'Legende',
        autoScroll: true
      }, {
        xtype: 'panel',
        title: 'Suche',
        autoScroll: true,
        id: COMP_IDS.SEARCH.PANEL,
        layout: {
          type: 'vbox',
          padding: 10
        },
        items: [{
          xtype: 'textfield',
          emptyText: 'Suchbegriff',
          id: COMP_IDS.SEARCH.TEXTFIELD,
          width: '100%',
          padding: 3
        }, {
          xtype: 'button',
          id: COMP_IDS.SEARCH.BUTTON,
          text: 'Suchen',
          width: '100%'
        }, {
          xtype: 'panel',
          height: '10px',
          id: COMP_IDS.SEARCH.EMPTY_MESSAGE
        }, {
          xtype: 'gridpanel',
          bodyBorder: true,
          frame: true,
          margin: "10 0 0 0",
          header: false,
          autoScroll: true,
          store: new Ext.data.ArrayStore({
            autoDestroy: true,
            idIndex: 0,
            groupField: 'layerName',
            fields: [
              'name',
              'layerName',
              'guid',
              'layerId',
              'lat', 'lon', 'x', 'y'
            ]
          }),
          width: '100%',
          id: COMP_IDS.SEARCH.RESULT_PANEL,
          hideHeaders: true,
          features: [{
            ftype: 'grouping',
            groupHeaderTpl: '{name}'
          }],
          columns: [{
            text: 'Stuff',
            flex: 1,
            dataIndex: 'name'
          }]
        }]
      },
        {
          xtype: 'panel',
          title: 'Wartungen',
          autoScroll: true,
          id: COMP_IDS.PAST_DUE.PANEL,
          layout: {
            type: 'vbox',
            padding: 10
          },
          items: [{
            xtype: 'panel',
            height: '10px',
            id: COMP_IDS.PAST_DUE.EMPTY_MESSAGE
          }, {
            xtype: 'gridpanel',
            bodyBorder: true,
            frame: true,
            margin: "10 0 0 0",
            header: false,
            autoScroll: true,
            store: new Ext.data.ArrayStore({
              autoDestroy: true,
              idIndex: 0,
              groupField: 'dsName',
              fields: [
                'name',
                'dsName',
                'guid',
                'layerId',
                'lat',
                'lon',
                'lastEntryPretty'
              ]
            }),
            width: '100%',
            id: COMP_IDS.PAST_DUE.RESULT_PANEL,
            hideHeaders: false,
            features: [{
              ftype: 'grouping',
              groupHeaderTpl: '{name}',
              startCollapsed: true
            }],
            columns: [{
              text: 'Name',
              flex: 1,
              dataIndex: 'name'
            }, {
              text: 'Letzte',
              flex: 1,
              dataIndex: 'lastEntryPretty'
            }]
          }]
        }, {
          xtype: 'panel',
          title: 'Mangelliste',
          autoScroll: true,
          id: COMP_IDS.LIST_MALFUNCTION.PANEL,
          layout: {
            type: 'vbox',
            padding: 10
          },
          items: [{
            xtype: 'panel',
            height: '10px',
            id: COMP_IDS.LIST_MALFUNCTION.EMPTY_MESSAGE
          }, {
            xtype: 'gridpanel',
            bodyBorder: true,
            frame: true,
            margin: "10 0 0 0",
            header: false,
            autoScroll: true,
            store: new Ext.data.ArrayStore({
              autoDestroy: true,
              idIndex: 0,
              groupField: 'dsName',
              fields: [
                'name',
                'dsName',
                'guid',
                'layerId',
                'lat',
                'lon',
                'lastEntryPretty'
              ]
            }),
            width: '100%',
            id: COMP_IDS.LIST_MALFUNCTION.RESULT_PANEL,
            hideHeaders: false,
            features: [{
              ftype: 'grouping',
              groupHeaderTpl: '{name}',
              startCollapsed: true
            }],
            columns: [{
              text: 'Name',
              flex: 1,
              dataIndex: 'name'
            }, {
              text: 'Datum',
              flex: 1,
              dataIndex: 'lastEntryPretty'
            }]
          }]
        }]
    }]
  }, {
    xtype: 'panel',
    border: false,
    margins: '3 3 3 0',
    flex: 1,
    region: 'center',
    width: 100,
    layout: 'border',
    id: COMP_IDS.PANEL_CENTER,
    items: [{
      xtype: 'panel',
      region: 'center',
      layout: 'fit',
      id: COMP_IDS.PANEL_MAP,
      html: '<div id="map_main" style="width:100%; height:100%" class="igis-map"></div><div id="map_image" class="igis-map" style="width:100%; height:100%; z-index: -100"><div>',
      tbar: {
        xtype: 'toolbar',
        dock: 'top',
        items: [{
          xtype: 'button',
          scale: 'medium',
          icon: pathIconPrintMapEnabled,
          tooltip: 'Karte drucken',
          id: COMP_IDS.PRINT_MAP
        }, {
          xtype: 'button',
          scale: 'medium',
          icon: pathIconPrintReport,
          tooltip: 'Bericht drucken',
          id: COMP_IDS.PRINT_REPORT,
          menu: {
            xtype: 'menu',
            items: [{
              xtype: 'button',
              text: txtNoReports,
              disabled: true
            }]
          }
        }, {
          xtype: 'tbseparator'
        }, {
          xtype: 'button',
          scale: 'medium',
          icon: pathIconAddGeometry,
          tooltip: "Objekt erstellen",
          id: COMP_IDS.GEOMETRY.ADD,
          menu: {
            xtype: 'menu',
            items: [{
              xtype: 'button',
              text: 'Keine Geometrieoperationen verfügbar',
              disabled: true
            }]
          }
        }, {
          xtype: 'tbseparator'
        }, {
          xtype: 'button',
          scale: 'medium',
          icon: pathIconAddDataEntry,
          tooltip: 'Datenerfassung starten',
          id: COMP_IDS.DATA_ENTRY.START,
          menu: {
            xtype: 'menu',
            items: [{
              xtype: 'button',
              text: txtNoDatasets,
              disabled: true
            }]
          }
        }, {
          xtype: 'button',
          scale: 'medium',
          icon: pathIconStopDataEntry,
          tooltip: 'Datenerfassung beenden',
          id: COMP_IDS.DATA_ENTRY.STOP
        }, {
          xtype: 'button',
          scale: 'medium',
          icon: pathIconCloseBatchDisabled,
          disabled: true,
          tooltip: "Erfassungsvorgang abschließen",
          id: COMP_IDS.DATA_ENTRY.CLOSE_BATCH,
          menu: {
            xtype: 'menu',
            items: []
          }
        }, {
          xtype: 'tbtext',
          id: COMP_IDS.DATA_ENTRY.OPEN_CNT,
          html: ''
        }, {
          xtype: 'tbtext',
          html: 'Druck, bitte warten...',
          id: COMP_IDS.PRINT_STATUS_MESSAGE,
          hidden: true
        }, {
          xtype: 'tbfill'
        }, {
          xtype: 'button',
          scale: 'medium',
          icon: pathIconSettings,
          tooltip: 'Einstellungen',
          id: COMP_IDS.SETTINGS
        }, {
          xtype: 'button',
          scale: 'medium',
          icon: pathIconHelp,
          tooltip: 'Changelog',
          id: COMP_IDS.CHANGELOG
        }, {
          xtype: 'tbseparator'
        }, {
          xtype: 'button',
          scale: 'medium',
          icon: pathIconLogout,
          tooltip: 'Logout',
          id: COMP_IDS.LOGOUT
        }]
      },
      bbar: {
        xtype: 'toolbar',
        items: [{
          xtype: 'tbtext',
          text: 'Wird geladen...',
          id: COMP_IDS.STATUS_TEXT
        }, {
          xtype: 'tbfill'
        }]
      }
    }]
  },
    {
      xtype: 'panel',
      title: 'Objekt',
      region: 'east',
      split: true,
      collapsible: true,
      width: 370,
      minWidth: 370,
      layout: 'vbox',
      align: 'stretch',
      pack: 'start',
      tbar: {
        xtype: 'toolbar',
        items: [{
          xtype: 'button',
          tooltip: 'Objekt öffnen',
          icon: pathIconEnterLevelsDisabled,
          scale: 'medium',
          disabled: true,
          id: COMP_IDS.ENTER_LEVELS
        }, {
          xtype: 'button',
          tooltip: 'Erfassungen / Dateien',
          icon: pathIconPictureDisabled,
          scale: 'medium',
          disabled: true,
          id: COMP_IDS.FEATURE_WINDOW
        }, {
          xtype: 'button',
          tooltip: 'Datei-Upload',
          icon: pathIconUploadDisabled,
          scale: 'medium',
          disabled: true,
          id: COMP_IDS.UPLOAD_BUTTON,
        }, {
          xtype: 'panel',
          hidden: true,
          html: '<input type="file" id="input_upload" multiple style="display:none">'
        }, {
          xtype: 'tbseparator'
        }, {
          xtype: 'button',
          scale: 'medium',
          disabled: true,
          icon: pathIconAddDataEntryDisabled,
          tooltip: '',
          id: COMP_IDS.DATA_ENTRY.START_FEATURE
        }, {
          xtype: 'tbseparator'
        }, {
          xtype: 'button',
          scale: 'medium',
          disabled: true,
          icon: pathIconPrintReportDisabled,
          tooltip: txtNoReports,
          id: COMP_IDS.PRINT_FEATURE_REPORT,
          menu: {
            xtype: 'menu',
            items: []
          }
        }, {
          xtype: 'tbseparator'
        }, {
          xtype: 'button',
          scale: 'medium',
          disabled: true,
          icon: pathIconMoveGeometryDisabled,
          tooltip: "Objekt bearbeiten",
          id: COMP_IDS.GEOMETRY.MOVE
        }, {
          xtype: 'button',
          disabled: true,
          scale: 'medium',
          icon: pathIconDelGeometryDisabled,
          tooltip: "Objekt löschen",
          id: COMP_IDS.GEOMETRY.DEL
        },]
      },
      items: [{
        xtype: 'panel',
        id: COMP_IDS.SHORT_INFO_PANEL,
        frame: true,
        width: '100%',
        html: ''
      }, {
        xtype: 'panel',
        id: COMP_IDS.FI_PANEL,
        title: 'Daten',
        layout: 'accordion',
        border: false,
        width: '100%',
        flex: 2,
        layoutConfig: {
          titleCollapse: true,
          animate: true,
          activeOnTop: false
        }
      }]
    }]
}];



