import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS, pathIconUploadDisabled, pathIconUploadEnabled} from "./GUIDefinitions";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {first} from "rxjs/operators";
import {FI} from "@igis-common/model/FI";
import {IGISConst} from "@igis-common/const";
import {Feature} from "@igis-common/model/Feature";

export class UploadButtonComponent extends Component {

  private curFeature: Feature | null;
  private uploadButton: ExtButton;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      this.uploadButton = Ext.getCmp<ExtButton>(COMP_IDS.UPLOAD_BUTTON);
      this.uploadButton.setHandler(this.onUpload, this);
      // listen to file-change events on the upload control
      const elem = document.getElementById('input_upload');
      if (elem) {
        console.log('registering upload listener');
        elem.onchange = () => {
          // @ts-ignore
          const selectedFiles = [...elem.files];
          this.onFileChange(selectedFiles);
        }
      } else {
        console.log('could not find upload component!!');
      }

      this.app.feature.selectFeature$.subscribe(feature => {
        this.curFeature = feature;
        let uploadAllowed = false;
        if (feature) {
          const layer = feature.layer;
          if (layer && layer.hasPermission(IGISConst.PERM_ADD_FILE)) {
            uploadAllowed = true;
          }
        }
        if (uploadAllowed) {
          this.uploadButton.setDisabled(false);
          this.uploadButton.setIcon(pathIconUploadEnabled);
        } else {
          this.uploadButton.setDisabled(true);
          this.uploadButton.setIcon(pathIconUploadDisabled);
        }
      })
    })
  }

  public onUpload() {
    const elem = document.getElementById('input_upload');
    if (elem) {
      elem.click();
    }
  }

  protected onFileChange(files) {
    if (!this.curFeature) {
      return;
    }
    for (let file of files) {
      this.app.upload.uploadFile(file, this.curFeature);
    }
  }
}
