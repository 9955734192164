
import {StorageEntry} from '../StorageEntry';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class UploadResultGen extends Model {
  protected _storageEntries: StorageEntry[];
  protected _featureGUID: string;

  constructor(json: {storageEntries: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean}[], featureGUID: string}, public _api: IGISApi) {
    super();
    this._storageEntries =  json.storageEntries.map(item => new StorageEntry(item, _api));
    this._featureGUID = json.featureGUID;
    this.onNew();
  }
}
