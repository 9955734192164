
import {AddDataEntryResult} from '../model/AddDataEntryResult';
import {ChangeFeatureResult} from '../model/ChangeFeatureResult';
import {DataEntry} from '../model/DataEntry';
import {DataSet} from '../model/DataSet';
import {DataStatus} from '../model/DataStatus';
import {Empty} from '../model/Empty';
import {Exports} from '../model/Exports';
import {FI} from '../model/FI';
import {FeatureActionResult} from '../model/FeatureActionResult';
import {FeatureCollection} from '../model/FeatureCollection';
import {FeatureEntry} from '../model/FeatureEntry';
import {FileResult} from '../model/FileResult';
import {Intervals} from '../model/Intervals';
import {LayerInfo} from '../model/LayerInfo';
import {ListResult} from '../model/ListResult';
import {ProjectInfo} from '../model/ProjectInfo';
import {ProjectList} from '../model/ProjectList';
import {ReportCount} from '../model/ReportCount';
import {SearchResult} from '../model/SearchResult';
import {Whoami} from '../model/Whoami';
import {Newable} from "@igis-common/api/Newable";
import {Observable, Subject} from "rxjs";

export enum ERROR_CODE {
  INTERNAL_ERROR = 1,
  PERMISSION_DENIED = 2,
  INVALID_LOGIN = 3,
  INVALID_SESSION = 4,
  SESSION_CLOSED = 5,
  NO_PROJECT = 6,
  INVALID_LAYER_ID = 7,
  MISSING_ARGUMENT = 8,
  INVALID_PARAMETER = 9,
  PROJECT_SET = 10,
  STANDBY = 11,
  REPORT_GEN = 12,
  LOGIN_EXPIRED = 13,
  NO_SERVER_CONNECTION = 1001
}

export interface ApiResult<T> {
  data: null|T;
  errorCode: null|ERROR_CODE;
}


export abstract class IGISApiGen {

  protected abstract rpc<T>(url: string, params: any, model: Newable<T>, errorMask?: ERROR_CODE[]): Promise<ApiResult<T> | null>;
  protected abstract rpcGET<T>(url: string, model: Newable<T>, errorMask?: ERROR_CODE[]): Promise<ApiResult<T> | null>;
  protected abstract rpcGETDownload(url: string): Promise<string | null>;
  protected abstract getProjectId(): number;

  // Rx subjects, can be used to listen to successful calls
  private fileRequestResultSubject: Subject<FileResult> = new Subject<FileResult>();
  public fileRequestResult$: Observable<FileResult> = this.fileRequestResultSubject;
  protected _addDataEntry$: Subject<AddDataEntryResult> = new Subject<AddDataEntryResult>();
  protected _addFeature$: Subject<FeatureEntry> = new Subject<FeatureEntry>();
  protected _assocFile$: Subject<ChangeFeatureResult> = new Subject<ChangeFeatureResult>();
  protected _deleteFeature$: Subject<FeatureActionResult> = new Subject<FeatureActionResult>();
  protected _deleteFile$: Subject<ChangeFeatureResult> = new Subject<ChangeFeatureResult>();
  protected _disassocFile$: Subject<ChangeFeatureResult> = new Subject<ChangeFeatureResult>();
  protected _filePoll$: Subject<FileResult> = new Subject<FileResult>();
  protected _finishBatch$: Subject<DataSet> = new Subject<DataSet>();
  protected _getDataEntry$: Subject<DataEntry> = new Subject<DataEntry>();
  protected _getDataStatus$: Subject<DataStatus> = new Subject<DataStatus>();
  protected _getExports$: Subject<Exports> = new Subject<Exports>();
  protected _getExtFeatureInfo$: Subject<FeatureEntry> = new Subject<FeatureEntry>();
  protected _getFeatureInfo$: Subject<FI> = new Subject<FI>();
  protected _getFeatures$: Subject<FeatureCollection> = new Subject<FeatureCollection>();
  protected _getIntervals$: Subject<Intervals> = new Subject<Intervals>();
  protected _getLayerInfo$: Subject<LayerInfo> = new Subject<LayerInfo>();
  protected _getProjectInfo$: Subject<ProjectInfo> = new Subject<ProjectInfo>();
  protected _listMalfunction$: Subject<ListResult> = new Subject<ListResult>();
  protected _listPastDue$: Subject<ListResult> = new Subject<ListResult>();
  protected _moveFeature$: Subject<FeatureActionResult> = new Subject<FeatureActionResult>();
  protected _newsletter$: Subject<Empty> = new Subject<Empty>();
  protected _printReportCount$: Subject<ReportCount> = new Subject<ReportCount>();
  protected _projectList$: Subject<ProjectList> = new Subject<ProjectList>();
  protected _resetLayerConfig$: Subject<Empty> = new Subject<Empty>();
  protected _saveLayerConfig$: Subject<Empty> = new Subject<Empty>();
  protected _search$: Subject<SearchResult> = new Subject<SearchResult>();
  protected _setInterval$: Subject<Empty> = new Subject<Empty>();
  protected _whoami$: Subject<Whoami> = new Subject<Whoami>();

  // URL generation
  private _getURLForAddDataEntry(projectId: number): string { return `/${projectId}/adddataentry`; }
  private _getURLForAddFeature(projectId: number): string { return `/${projectId}/addfeature`; }
  private _getURLForAssocFile(projectId: number): string { return `/${projectId}/assocfile`; }
  private _getURLForChangelog(projectId: number, force: number): string { return `/${projectId}/changelog/${encodeURIComponent(force)}`; }
  private _getURLForDeleteFeature(projectId: number): string { return `/${projectId}/deletefeature`; }
  private _getURLForDeleteFile(projectId: number): string { return `/${projectId}/deletefile`; }
  private _getURLForDisassocFile(projectId: number): string { return `/${projectId}/disassocfile`; }
  private _getURLForExportShp(projectId: number): string { return `/${projectId}/export/shp`; }
  private _getURLForFilePoll(projectId: number, uuid: string): string { return `/${projectId}/file/poll/${encodeURIComponent(uuid)}`; }
  private _getURLForFinishBatch(projectId: number): string { return `/${projectId}/finishbatch`; }
  private _getURLForGetDataEntry(projectId: number): string { return `/${projectId}/getdataentry`; }
  private _getURLForGetDataStatus(projectId: number): string { return `/${projectId}/getdatastatus`; }
  private _getURLForGetExports(projectId: number): string { return `/${projectId}/getexports`; }
  private _getURLForGetExtFeatureInfo(projectId: number): string { return `/${projectId}/getextfeatureinfo`; }
  private _getURLForGetFeatureInfo(projectId: number): string { return `/${projectId}/getfeatureinfo`; }
  private _getURLForGetFeatures(projectId: number): string { return `/${projectId}/getfeatures`; }
  private _getURLForGetIntervals(projectId: number): string { return `/${projectId}/getintervals`; }
  private _getURLForGetLayerInfo(projectId: number): string { return `/${projectId}/getlayerinfo`; }
  private _getURLForGetProjectInfo(projectId: number): string { return `/${projectId}/getprojectinfo`; }
  private _getURLForListMalfunction(projectId: number): string { return `/${projectId}/listmalfunction`; }
  private _getURLForListPastDue(projectId: number): string { return `/${projectId}/listpastdue`; }
  private _getURLForMoveFeature(projectId: number): string { return `/${projectId}/movefeature`; }
  private _getURLForNewsletter(projectId: number): string { return `/${projectId}/newsletter`; }
  private _getURLForPrintFeatureReport(projectId: number): string { return `/${projectId}/print/featurereport`; }
  private _getURLForPrintMap(projectId: number): string { return `/${projectId}/print/map`; }
  private _getURLForPrintReport(projectId: number): string { return `/${projectId}/print/report`; }
  private _getURLForPrintReportCount(projectId: number): string { return `/${projectId}/print/reportcount`; }
  private _getURLForProjectList(): string { return `/projectlist`; }
  private _getURLForResetLayerConfig(projectId: number): string { return `/${projectId}/resetlayerconfig`; }
  private _getURLForSaveLayerConfig(projectId: number): string { return `/${projectId}/savelayerconfig`; }
  private _getURLForSearch(projectId: number): string { return `/${projectId}/search`; }
  private _getURLForSetInterval(projectId: number): string { return `/${projectId}/setinterval`; }
  private _getURLForWhoami(): string { return `/whoami`; }
  private _getURLForWmsLg(projectId: number, width: number, height: number, bbox: string, layers: string): string { return `/${projectId}/wms/lg/${encodeURIComponent(width)}/${encodeURIComponent(height)}/${encodeURIComponent(bbox)}/${encodeURIComponent(layers)}`; }

  // real calls
  public async addDataEntry
  (
    params: {
      dataSetId: number,
      values: {fieldId: number, value: string, lastPos?: {zoom: number, lat: number, lng: number}}[],
      fguid: string,
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<AddDataEntryResult> | null> {
    const result = await this.rpc<AddDataEntryResult>(this._getURLForAddDataEntry(this.getProjectId()), params, AddDataEntryResult, errorMask);
    if (result && result.data) {
      this._addDataEntry$.next(result.data);
    }
    return result;
  }
  public async addFeature
  (
    params: {
      dataSetId: number,
      geoJson: string,
      altitudes?: number[],
      accuracies?: number[],
      values: {fieldId: number, value: string, lastPos?: {zoom: number, lat: number, lng: number}}[],
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<FeatureEntry> | null> {
    const result = await this.rpc<FeatureEntry>(this._getURLForAddFeature(this.getProjectId()), params, FeatureEntry, errorMask);
    if (result && result.data) {
      this._addFeature$.next(result.data);
    }
    return result;
  }
  public async assocFile
  (
    params: {
      dataEntryId: number,
      fileGUID: string
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<ChangeFeatureResult> | null> {
    const result = await this.rpc<ChangeFeatureResult>(this._getURLForAssocFile(this.getProjectId()), params, ChangeFeatureResult, errorMask);
    if (result && result.data) {
      this._assocFile$.next(result.data);
    }
    return result;
  }
  public async changelog
  (
    force: number,
    errorMask?: ERROR_CODE[]
  ): Promise<string | null> {
    return '/api/download/' + await this.rpcGETDownload(this._getURLForChangelog(this.getProjectId(), force));
  }
  public async deleteFeature
  (
    params: {
      fguid: string,
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<FeatureActionResult> | null> {
    const result = await this.rpc<FeatureActionResult>(this._getURLForDeleteFeature(this.getProjectId()), params, FeatureActionResult, errorMask);
    if (result && result.data) {
      this._deleteFeature$.next(result.data);
    }
    return result;
  }
  public async deleteFile
  (
    params: {
      fileGUID: string
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<ChangeFeatureResult> | null> {
    const result = await this.rpc<ChangeFeatureResult>(this._getURLForDeleteFile(this.getProjectId()), params, ChangeFeatureResult, errorMask);
    if (result && result.data) {
      this._deleteFile$.next(result.data);
    }
    return result;
  }
  public async disassocFile
  (
    params: {
      fileGUID: string
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<ChangeFeatureResult> | null> {
    const result = await this.rpc<ChangeFeatureResult>(this._getURLForDisassocFile(this.getProjectId()), params, ChangeFeatureResult, errorMask);
    if (result && result.data) {
      this._disassocFile$.next(result.data);
    }
    return result;
  }
  public  exportShp
  (
    params: {
      exportId: number
    },
    errorMask?: ERROR_CODE[]
  ): void {
    this.rpc<FileResult>(this._getURLForExportShp(this.getProjectId()), params, FileResult, errorMask)
    .then(result => {
      if (result && result.data) {
        this.fileRequestResultSubject.next(result.data);
      }
    });
  }
  public async filePoll
  (
    uuid: string,
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<FileResult> | null> {
    const result = await this.rpcGET<FileResult>(this._getURLForFilePoll(this.getProjectId(), uuid), FileResult, errorMask);
    if (result && result.data) {
      this._filePoll$.next(result.data);
    }
    return result;
  }
  public async finishBatch
  (
    params: {
      dataSetId: number,
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<DataSet> | null> {
    const result = await this.rpc<DataSet>(this._getURLForFinishBatch(this.getProjectId()), params, DataSet, errorMask);
    if (result && result.data) {
      this._finishBatch$.next(result.data);
    }
    return result;
  }
  public async getDataEntry
  (
    params: {
      dataEntryId: number,
      fguid: string,
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<DataEntry> | null> {
    const result = await this.rpc<DataEntry>(this._getURLForGetDataEntry(this.getProjectId()), params, DataEntry, errorMask);
    if (result && result.data) {
      this._getDataEntry$.next(result.data);
    }
    return result;
  }
  public async getDataStatus
  (
    
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<DataStatus> | null> {
    const result = await this.rpcGET<DataStatus>(this._getURLForGetDataStatus(this.getProjectId()), DataStatus, errorMask);
    if (result && result.data) {
      this._getDataStatus$.next(result.data);
    }
    return result;
  }
  public async getExports
  (
    
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<Exports> | null> {
    const result = await this.rpcGET<Exports>(this._getURLForGetExports(this.getProjectId()), Exports, errorMask);
    if (result && result.data) {
      this._getExports$.next(result.data);
    }
    return result;
  }
  public async getExtFeatureInfo
  (
    params: {
      fguid: string,
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<FeatureEntry> | null> {
    const result = await this.rpc<FeatureEntry>(this._getURLForGetExtFeatureInfo(this.getProjectId()), params, FeatureEntry, errorMask);
    if (result && result.data) {
      this._getExtFeatureInfo$.next(result.data);
    }
    return result;
  }
  public async getFeatureInfo
  (
    params: {
      fguid: string,
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<FI> | null> {
    const result = await this.rpc<FI>(this._getURLForGetFeatureInfo(this.getProjectId()), params, FI, errorMask);
    if (result && result.data) {
      this._getFeatureInfo$.next(result.data);
    }
    return result;
  }
  public async getFeatures
  (
    params: {
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<FeatureCollection> | null> {
    const result = await this.rpc<FeatureCollection>(this._getURLForGetFeatures(this.getProjectId()), params, FeatureCollection, errorMask);
    if (result && result.data) {
      this._getFeatures$.next(result.data);
    }
    return result;
  }
  public async getIntervals
  (
    
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<Intervals> | null> {
    const result = await this.rpcGET<Intervals>(this._getURLForGetIntervals(this.getProjectId()), Intervals, errorMask);
    if (result && result.data) {
      this._getIntervals$.next(result.data);
    }
    return result;
  }
  public async getLayerInfo
  (
    params: {
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<LayerInfo> | null> {
    const result = await this.rpc<LayerInfo>(this._getURLForGetLayerInfo(this.getProjectId()), params, LayerInfo, errorMask);
    if (result && result.data) {
      this._getLayerInfo$.next(result.data);
    }
    return result;
  }
  public async getProjectInfo
  (
    
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<ProjectInfo> | null> {
    const result = await this.rpcGET<ProjectInfo>(this._getURLForGetProjectInfo(this.getProjectId()), ProjectInfo, errorMask);
    if (result && result.data) {
      this._getProjectInfo$.next(result.data);
    }
    return result;
  }
  public async listMalfunction
  (
    params: {
      layerIds?: number[]
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<ListResult> | null> {
    const result = await this.rpc<ListResult>(this._getURLForListMalfunction(this.getProjectId()), params, ListResult, errorMask);
    if (result && result.data) {
      this._listMalfunction$.next(result.data);
    }
    return result;
  }
  public async listPastDue
  (
    params: {
      layerIds?: number[]
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<ListResult> | null> {
    const result = await this.rpc<ListResult>(this._getURLForListPastDue(this.getProjectId()), params, ListResult, errorMask);
    if (result && result.data) {
      this._listPastDue$.next(result.data);
    }
    return result;
  }
  public async moveFeature
  (
    params: {
      geoJSON: string,
      fguid: string,
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<FeatureActionResult> | null> {
    const result = await this.rpc<FeatureActionResult>(this._getURLForMoveFeature(this.getProjectId()), params, FeatureActionResult, errorMask);
    if (result && result.data) {
      this._moveFeature$.next(result.data);
    }
    return result;
  }
  public async newsletter
  (
    params: {
      agree: boolean
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<Empty> | null> {
    const result = await this.rpc<Empty>(this._getURLForNewsletter(this.getProjectId()), params, Empty, errorMask);
    if (result && result.data) {
      this._newsletter$.next(result.data);
    }
    return result;
  }
  public  printFeatureReport
  (
    params: {
      reportId: number,
      fguid: string,
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): void {
    this.rpc<FileResult>(this._getURLForPrintFeatureReport(this.getProjectId()), params, FileResult, errorMask)
    .then(result => {
      if (result && result.data) {
        this.fileRequestResultSubject.next(result.data);
      }
    });
  }
  public  printMap
  (
    params: {
      extent: number[],
      layers: string[],
      scale: number,
      format: string,
      orientation: string,
      withTable: boolean
    },
    errorMask?: ERROR_CODE[]
  ): void {
    this.rpc<FileResult>(this._getURLForPrintMap(this.getProjectId()), params, FileResult, errorMask)
    .then(result => {
      if (result && result.data) {
        this.fileRequestResultSubject.next(result.data);
      }
    });
  }
  public  printReport
  (
    params: {
      reportId: number,
      filter?: {from?: string, to?: string, open?: boolean, malfunction?: boolean},
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): void {
    this.rpc<FileResult>(this._getURLForPrintReport(this.getProjectId()), params, FileResult, errorMask)
    .then(result => {
      if (result && result.data) {
        this.fileRequestResultSubject.next(result.data);
      }
    });
  }
  public async printReportCount
  (
    params: {
      reportId: number,
      filter?: {from?: string, to?: string, open?: boolean, malfunction?: boolean},
      layerId: number,
      levelFeatureGUID?: string,
      levelId?: number,
      levelLayerId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<ReportCount> | null> {
    const result = await this.rpc<ReportCount>(this._getURLForPrintReportCount(this.getProjectId()), params, ReportCount, errorMask);
    if (result && result.data) {
      this._printReportCount$.next(result.data);
    }
    return result;
  }
  public async projectList
  (
    
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<ProjectList> | null> {
    const result = await this.rpcGET<ProjectList>(this._getURLForProjectList(), ProjectList, errorMask);
    if (result && result.data) {
      this._projectList$.next(result.data);
    }
    return result;
  }
  public async resetLayerConfig
  (
    params: {

    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<Empty> | null> {
    const result = await this.rpc<Empty>(this._getURLForResetLayerConfig(this.getProjectId()), params, Empty, errorMask);
    if (result && result.data) {
      this._resetLayerConfig$.next(result.data);
    }
    return result;
  }
  public async saveLayerConfig
  (
    params: {
      invisibleLayers: number[],
      visibleLayers: number[],
      invisibleBasemapLayers: number[]
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<Empty> | null> {
    const result = await this.rpc<Empty>(this._getURLForSaveLayerConfig(this.getProjectId()), params, Empty, errorMask);
    if (result && result.data) {
      this._saveLayerConfig$.next(result.data);
    }
    return result;
  }
  public async search
  (
    params: {
      queryString: string,
      layerIds: number[],
      levelFeatureGUID?: string,
      levelId?: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<SearchResult> | null> {
    const result = await this.rpc<SearchResult>(this._getURLForSearch(this.getProjectId()), params, SearchResult, errorMask);
    if (result && result.data) {
      this._search$.next(result.data);
    }
    return result;
  }
  public async setInterval
  (
    params: {
      dataSetId: number,
      newInterval: number
    },
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<Empty> | null> {
    const result = await this.rpc<Empty>(this._getURLForSetInterval(this.getProjectId()), params, Empty, errorMask);
    if (result && result.data) {
      this._setInterval$.next(result.data);
    }
    return result;
  }
  public async whoami
  (
    
    errorMask?: ERROR_CODE[]
  ): Promise<ApiResult<Whoami> | null> {
    const result = await this.rpcGET<Whoami>(this._getURLForWhoami(), Whoami, errorMask);
    if (result && result.data) {
      this._whoami$.next(result.data);
    }
    return result;
  }
  public async wmsLg
  (
    width: number, height: number, bbox: string, layers: string,
    errorMask?: ERROR_CODE[]
  ): Promise<string | null> {
    return '/api/download/' + await this.rpcGETDownload(this._getURLForWmsLg(this.getProjectId(), width, height, bbox, layers));
  }
}