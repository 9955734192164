import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS, pathIconEnterLevelsDisabled, pathIconEnterLevelsEnabled} from "./GUIDefinitions";
import {FI} from "@igis-common/model/FI";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {first} from "rxjs/operators";
import {Feature} from "@igis-common/model/Feature";

export class LevelButtonComponent extends Component {

  protected levelButton: ExtButton;

  protected curFeature: Feature | null;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      this.levelButton = <ExtButton>Ext.getCmp(COMP_IDS.ENTER_LEVELS);
      this.levelButton.setHandler(this.onLevelButtonClick, this);

      this.app.feature.updateSelectedFeature$.subscribe((feature) => {
        this.curFeature = feature;
        if (feature && feature.fi.hasLevels) {
          this.enableEnterButton(true);
        } else {
          this.enableEnterButton(false);
        }
      })
    })
  }

  public onLevelButtonClick(): void {
    if (this.curFeature) {
      this.app.levelFeature.enterLevelFeature(this.curFeature);
    }
  }

  protected enableEnterButton(enable: boolean): void {
    if (enable) {
      this.levelButton.setDisabled(false);
      this.levelButton.setIcon(pathIconEnterLevelsEnabled);
    } else {
      this.levelButton.setDisabled(true);
      this.levelButton.setIcon(pathIconEnterLevelsDisabled);
    }
  }
}
