
import {Attribute} from '../Attribute';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class DataFieldValueGen extends Model {
  protected _name: string;
  protected _value: string;
  protected _orderNr: number;
  protected _attribute: Attribute;

  constructor(json: {name: string, value: string, orderNr: number, attribute: {name: string, displayName: string, info: string, unit: string, digits: number, type: number, searchable: boolean, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean}}[]}}, public _api: IGISApi) {
    super();
    this._name = json.name;
    this._value = json.value;
    this._orderNr = json.orderNr;
    this._attribute = new Attribute(json.attribute, _api);
    this.onNew();
  }
}
