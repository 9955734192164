import {LayerGen} from "./generated/LayerGen";
import {DataSet} from "@igis-common/model/DataSet";
import {Report} from "@igis-common/model/Report";
import {FI} from "@igis-common/model/FI";
import {Observable, Subject} from "rxjs";
import {IGISConst} from "@igis-common/const";
import {sortByLCCategoryName, sortByLCName, sortByOrderNr} from "@igis-common/api/Util";
import {Attribute} from "@igis-common/model/Attribute";
import {FeatureCollection} from "@igis-common/model/FeatureCollection";
import {VisualizationColumn} from "@igis-common/model/VisualizationColumn";
import {RootLayer} from "@igis-common/RootLayer";
import {TreeNodeConfig} from "@igis-common/TreeNodeConfig";
import {LevelFeature} from "@igis-common/model/Feature";
import {LayerInfo} from "@igis-common/model/LayerInfo";
import {FIAttr} from "@igis-common/model/FIAttr";
import {BasemapMapLayer} from "@igis-common/leaflet/BasemapMapLayer";
import {FIGroupDto} from "@igis-common/model/FIGroupDto";
import {FIGroupInfo} from "@igis-common/model/FIGroupInfo";
import {ProjectPolicy} from "@igis-common/api/policy/ProjectPolicy";


export enum LayerEventType {
  NEW_VISIBLE, RELOAD
}

export enum VISIBILITY {
  VISIBLE, INVISIBLE, PARENT_INVISIBLE
}

interface INotifyEvent {
  layer: Layer;
  eventType: LayerEventType;
  newVisible: boolean;
}

export interface LayerTreeNodeConfig extends TreeNodeConfig {
  layer: Layer;
  registered: boolean;
}

export interface LayerIdMap {
  [layerId: number]: boolean
}

export interface LayerMap {
  [layerId: number]: Layer
}

export class Layer extends LayerGen {

  protected _wms: boolean = true;

  protected _visible: boolean | undefined;

  protected _isRoot: boolean = false;

  protected _features: FI[] = [];
  protected _parent: Layer | null = null;
  protected _children: Layer[] = [];
  protected _imageLayers: Layer[] = [];

  protected _dataSets: DataSet[] = [];
  protected _visibleStack: boolean[] = [];

  protected _layerInfo: LayerInfo | null = null;
  protected _projectPolicy: ProjectPolicy | null = null;

  protected stateChangesRecursiveSubject = new Subject<INotifyEvent>();
  public stateChangesRecursive$: Observable<INotifyEvent> = this.stateChangesRecursiveSubject;

  protected reloadSubject = new Subject<INotifyEvent>();
  public reload$: Observable<INotifyEvent> = this.reloadSubject;

  protected visibilityChangeSubject = new Subject<VISIBILITY>();
  public visibilityChange$: Observable<VISIBILITY> = this.visibilityChangeSubject;
  protected oldVisibility: VISIBILITY | null = null;

  protected lastOpenFIGroup: FIGroupInfo | null = null;

  protected onNew() {
    // fix name singular
    if (!this._nameSingular || this._nameSingular.length == 0) {
      this._nameSingular = this._name;
    }
    // save the initial visible state
    this._visible = this._defaultVisible;
    this.oldVisibility = this._visible ? VISIBILITY.VISIBLE : VISIBILITY.INVISIBLE;

    // set layer reference in reports
    for(const report of this._reports) {
      report.layer = this;
    }
  }

  public addChild(child: Layer) {
    this._group = true;
    this._children.push(child);
    child._parent = this;

    // register in child's subject to bubble up state changes
    child.stateChangesRecursive$.subscribe(stateChangeEvent => {
      if (stateChangeEvent.eventType == LayerEventType.NEW_VISIBLE)
        if (stateChangeEvent.newVisible) {
          // one of our children was set to visible, we have to be visible too
          this.visible = true;
        } else {
          this.checkChildrenVisible();
        }

      // bubble up
      this.stateChangesRecursiveSubject.next(stateChangeEvent);
    })
  }

  get isBasemap(): boolean {
    return false;
  }

  set projectPolicy(projectPolicy: ProjectPolicy | null) {
    this._projectPolicy = projectPolicy;
  }

  /**
   * Saves the current layer visibility configuration
   */
  public pushVisibleState(): void {
    this._visibleStack.push(!!this._visible);
    // save children
    for (let child of this._children) {
      child.pushVisibleState();
    }
  }

  /**
   * Restores the layer visibility
   */
  public popVisibleState(): void {
    if (this._visibleStack.length > 0) {
      const oldValue = this._visibleStack.pop();
      this.visible = !!oldValue;
    }
    // pop children
    for (let child of this._children) {
      child.popVisibleState();
    }
  }

  public addImageLayer(imageLayer: Layer): void {
    this._imageLayers.push(imageLayer);
  }

  get children(): Layer[] {
    return this._children;
  }

  get imageLayers(): Layer[] {
    return this._imageLayers;
  }

  get wms(): boolean {
    return this._wms;
  }

  get id(): number {
    return this._id;
  }

  get isImageLayer(): boolean {
    return this._imageLayer;
  }

  //// name
  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get nameSingular(): string {
    return this._nameSingular;
  }

  /**
   * Returns our own name and our parents name, if available
   */
  get recursiveName(): string {
    if (this._parent) {
      return this._parent.name + ' -> ' + this.name;
    } else {
      return this.name;
    }
  }

  //// visible
  get visible(): boolean | undefined {
    return this._visible;
  }

  set visible(visible: boolean | undefined) {
    const notify = this._visible !== visible;
    this._visible = visible;
    if (notify) {
      this.notifyVisible();
      // our children may now be visible/invisible due to our new visibility: notify them
      this.checkDeepVisibility();
    }
  }

  public checkDeepVisibility() {
    let newDeepVis;
    if (this.parentVisible) {
      if (this.visible) {
        newDeepVis = VISIBILITY.VISIBLE;
      } else {
        newDeepVis = VISIBILITY.INVISIBLE;
      }
    } else {
      // our parent is not visible
      if (this.visible) {
        newDeepVis = VISIBILITY.PARENT_INVISIBLE;
      } else {
        // nobody is visible
        newDeepVis = VISIBILITY.INVISIBLE;
      }
    }
    if (this.oldVisibility !== newDeepVis) {
      this.visibilityChangeSubject.next(newDeepVis);
      this.oldVisibility = newDeepVis;
    }
    for (let child of this._children) {
      child.checkDeepVisibility();
    }
  }

  /**
   * Determines if every parent of this layer (up until the root-layer) is visible.
   * If false, then this layer is also not displayed, even if visible itself.
   */
  get parentVisible(): boolean {
    if (!this.visible) {
      return false;
    }
    if (this._parent) {
      // go further up the tree
      return this._parent.parentVisible;
    }
    return true; // we are the last one and visible
  }

  public toggleVisible(): boolean {
    this.visible = !this.visible;
    return true;
  }

  public checkChildrenVisible(): void {
    // one of our children was set to invisible, check if all children are invisible, and set our own
    // visibility accordingly
    let allInvisible = true;
    for (let child of this.children) {
      if (child.visible) {
        allInvisible = false;
        break;
      }
    }
    if (allInvisible && this.children.length > 0) {
      // set ourself invisible
      this.visible = false;
    }
  }

  get nameAttributes(): Attribute[] {
    return this._nameAttributes;
  }

  get shortInfoAttributes(): Attribute[] {
    return this._shortInfoAttributes;
  }

  public addDataSet(dataSet: DataSet): void {
    this._dataSets.push(dataSet);
  }

  public notifyVisible() {
    const newState = {
      layer: this,
      eventType: LayerEventType.NEW_VISIBLE,
      newVisible: !!this._visible
    }
    this.stateChangesRecursiveSubject.next(newState);
  }

  public isChild(layers: Layer[]): boolean {
    for (let child of this.children) {
      for (let layer of layers) {
        if (layer === child || child.isChild([layer])) {
          return true;
        }
      }
    }
    return false;
  }

  //// isGroup
  get isGroup(): boolean {
    return this._group;
  }

  set isGroup(isGroup: boolean) {
    this._group = isGroup;
  }

  get dataSets(): DataSet[] {
    return this._dataSets;
  }

  get geomType(): string {
    return this._geomType;
  }

  get geomanType(): "CircleMarker" | "Line" | "Polygon" | null {
    switch (this.geomType) {
      case 'POINT':
        return "CircleMarker";
      case 'LINE':
      case 'MULTILINESTRING':
        return "Line";
      case 'POLYGON':
      case 'MULTIPOLYGON':
        return "Polygon";
    }
    return null;
  }

  get orderNr(): number {
    return this._orderNr;
  }

  get parentId(): number {
    return this._parentId;
  }

  get basemapId(): number | undefined {
    return undefined;
  }

  get maxVertices(): number {
    return this._maxVertices;
  }

  public getFeatureDataSets(): DataSet[] {
    return this._dataSets;
  }

  public resetFeatures(): void {
    this._features = [];
  }

  public reload(): void {
    // layer should be reloaded
    const event = {
      eventType: LayerEventType.RELOAD,
      layer: this,
      newVisible: !!this.visible
    }
    this.stateChangesRecursiveSubject.next(event);
    this.reloadSubject.next(event);
  }

  get features(): FI[] {
    return this._features;
  }

  get addFeatureDataSet(): DataSet | undefined {
    return this._addFeatureDataSet;
  }

  public hasPermission(perm: number): boolean {
    let found = false;
    if (!this._availPerms) return false;
    for (let lPerm of this._availPerms) {
      if (lPerm == perm) {
        found = true;
        break;
      }
    }
    if (!found) {
      return false; // permission is not in the list of available ones
    }
    return this._projectPolicy?.hasPermission(this.id, perm) ?? false;
  }

  /**
   * Returns the list of Reports with the featureSel attribute set to true
   * @returns {Report[]}
   */
  public getFeatureSelReports(): Report[] {
    let reports: Report[] = [];
    for (let report of this._reports) {
      if (report.isFeatureSelect) {
        reports.push(report);
      }
    }
    sortByLCName(reports);
    return reports;
  }

  get reports(): Report[] {
    return this._reports;
  }

  /**
   * Identifier for communication with GIS server (id of layer)
   */
  get wmsName(): string {
    return this._id + '';
  }

  /**
   * Replace DataSet information from JSON data
   * @param setId
   * @param jsonSet
   * @returns {boolean} true if DataSet was replaced
   */
  public updateSet(setId: number, jsonSet): boolean {
    for (let ii = 0; ii < this._dataSets.length; ii++) {
      const dataSet = this._dataSets[ii];
      if (dataSet.id === setId) {
        this._dataSets[ii] = new DataSet(jsonSet, this._api);
        return true;
      }
    }
    return false;
  }

  public setVisibilityFromUserConfig(invLayers: LayerIdMap, visibleLayers: LayerIdMap, invBMLayers: LayerIdMap) {
    for (let child of this._children) {
      child.setVisibilityFromUserConfig(invLayers, visibleLayers, invBMLayers)
    }
    if (this.isBasemap) {
      // @ts-ignore
      const isInvisible = invBMLayers[this.basemapId] !== undefined;
      this.visible = !isInvisible;
    } else {
      const isInvisible = invLayers[this.id] !== undefined;
      const isVisible = visibleLayers[this.id] !== undefined;
      if (isInvisible) {
        this.visible = false;
      }
      if (isVisible) {
        this.visible = true;
      }
    }
  }

  public fillVisibilityLists(invLayers: number[], visLayers: number[], invBMLayers: number[]) {
    if (!this.visible && this.id) {
      invLayers.push(this.id)
    }
    if (this.visible && this.id) {
      visLayers.push(this.id);
    }
    if (this.isBasemap && (!this.visible || !this.parentVisible)) {
      invBMLayers.push(<number>this.basemapId);
    }
    for (let child of this._children) {
      child.fillVisibilityLists(invLayers, visLayers, invBMLayers);
    }
  }

  public hasParent(): boolean {
    return (this._parentId !== null && this._parentId !== undefined);
  }

  public get layerInfo(): LayerInfo | null {
    return this._layerInfo;
  }

  public set layerInfo(layerInfo: LayerInfo | null) {
    this._layerInfo = layerInfo;
  }

  public buildTree(layerMap: LayerMap): void {
    // build children
    this._children = [];
    for (let layerId in layerMap) {
      const layer = layerMap[layerId];
      if (layer._parentId == this._id) {
        this.addChild(layer);
      }
    }
    this.sortChildren();
    if (this._parent) return; // we already have set a parent
    if (!this._parentId) return; // we are a root layer
    this._parent = layerMap[this._parentId]; // find our parent
  }

  public sortChildren(): void {
    sortByOrderNr(this._children);
  }

  public dumpLayerTree() {
    console.log(this.name);
    for (let child of this._children) {
      child.dumpLayerTree();
    }
  }

  /**
   * Retrieves all children of this layer, recursively
   */
  public getAllChildren(): Layer[] {
    const layerList: Layer[] = [];
    for (let child of this._children) {
      child.addNonGroupLayers(layerList);
    }
    // maybe we are not a group but a simple layer?
    if (!this.isGroup) {
      layerList.push(this);
    }
    return layerList;
  }

  /**
   * Retrieves all visible children of this layer, recursively
   * Returns one entry (this) if this is not a group and we are visible.
   */
  public getVisibleChildren(needWMS: boolean = false): Layer[] {
    const layerList: Layer[] = [];
    if (!this._visible) {
      return layerList; // nothing to do
    }
    for (let child of this._children) {
      child.addChildLayers(layerList, {needQueryable: false, needVisible: true, needSomePerm: false, needWMS});
    }
    // maybe we are not a group but a simple layer?
    if (!this.isGroup && this.visible) {
      layerList.push(this);
    }
    return layerList;
  }

  /**
   * Retrieves all visible and queryable children of this layer, recursively.
   * Returns one entry (this) if this is not a group and visible and queryable.
   */
  public getQueryableChildren(): Layer[] {
    const layerList: Layer[] = [];
    if (!this._visible) {
      return layerList; // nothing to do
    }
    for (let child of this._children) {
      child.addChildLayers(layerList, {needQueryable: true, needVisible: true, needSomePerm: false, needWMS: false});
    }
    // maybe we are not a group but a simple layer?
    if (!this.isGroup && this.visible && this._queryable) {
      layerList.push(this);
    }
    return layerList;
  }

  /**
   * Retrieves all queryable children of this layer that have at least one data set defined, recursively.
   */
  public getDataSetChildren(): Layer[] {
    const layerList: Layer[] = [];
    if (!this._visible) {
      return layerList; // nothing to do
    }
    for (let child of this._children) {
      child.addChildLayers(layerList, {
        needQueryable: true, needVisible: true, needSomePerm: false,
        needWMS: false, needDataSet: true
      });
    }
    // maybe we are not a group but a simple layer?
    if (!this.isGroup && this.visible && this._queryable && this.getFeatureDataSets().length > 0) {
      layerList.push(this);
    }
    return layerList;
  }

  /**
   * Retrieves all children which either are able to add a dataEntry or add a geometry.
   */
  public getProcessableChildren(): Layer[] {
    const layers: Layer[] = [];
    for (let child of this._children) {
      child.addChildLayers(layers, {needQueryable: false, needVisible: true, needSomePerm: true, needWMS: false});
    }
    sortByLCName(layers);
    return layers;
  }

  /**
   * Return Reports of all visible children of this layer
   */
  public getChildLayerReports(): Report[] {
    const layers: Layer[] = [];
    for (let child of this._children) {
      child.addChildLayers(layers, {needQueryable: false, needVisible: true, needSomePerm: true, needWMS: false});
    }
    let reports: Report[] = [];
    for (const layer of layers) {
      const layerReports = layer.reports;
      if (layerReports) {
        for (let report of layerReports) {
          if (!report.isFeatureSelect) {
            reports.push(report);
          }
        }
      }
    }
    sortByLCCategoryName(reports);
    return reports;
  }

  public getGeomChildren(permission: number): Layer[] {
    const layers: Layer[] = [];
    for (let child of this._children) {
      child.addChildLayers(layers, {
        needQueryable: true,
        needVisible: false,
        needSomePerm: true,
        needWMS: false
      }, permission);
    }
    sortByLCName(layers);
    return layers;
  }

  protected addChildLayers(layerList: Layer[], flags: {
    needQueryable: boolean,
    needVisible: boolean,
    needSomePerm: boolean,
    needWMS: boolean,
    needDataSet?: boolean
  }, permission: number | null = null, geomType: string | null = null): void {
    if (this.isGroup) {
      if ((flags.needVisible && this.visible) || !flags.needVisible) {
        // add our children
        for (let child of this._children) {
          child.addChildLayers(layerList, flags, permission, geomType);
        }
      }
    } else {
      // should we add ourself?
      // decide on visibility
      if (!(flags.needVisible && this.visible) && flags.needVisible) {
        return;
      }
      // decide on queryable
      if (!(flags.needQueryable && this._queryable) && flags.needQueryable) {
        return;
      }
      // decide if wms-layer
      if (!(flags.needWMS && this.wms) && flags.needWMS) {
        return;
      }
      if (permission && !this.hasPermission(permission)) {
        return;
      }
      if (flags.needDataSet && !this.getFeatureDataSets().length) {
        return;
      }
      // decide on permission
      const dataSets = this.hasPermission(IGISConst.PERM_DATASET) && this.dataSets.length > 0;
      const addGeom = this.hasPermission(IGISConst.PERM_ADD_GEOM) && (!geomType || this.geomType === geomType);
      if (!flags.needSomePerm || (flags.needSomePerm && (addGeom || dataSets))) {
        layerList.push(this);
      }
    }
  }

  public addNonGroupLayers(layerList: Layer[]) {
    if (this.isGroup) {
      // add our children
      for (let child of this._children) {
        child.addNonGroupLayers(layerList);
      }
    } else {
      layerList.push(this);
    }
  }

  /**
   * Adds all visible children that have a mapReport to the given list
   * @param layerList
   */
  public addVisibleMapReportLayers(layerList: Layer[]) {
    if (this.isGroup && this.visible) {
      // add our children
      for (let child of this._children) {
        child.addVisibleMapReportLayers(layerList);
      }
    } else {
      if (this._mapReport && this.visible) {
        layerList.push(this);
      }
    }
  }

  public resetVisibility(): void {
    for (let child of this._children) {
      child.resetVisibility();
    }
    this.visible = this._defaultVisible;
  }

  public hasSubLayer(layer: Layer): boolean {
    let res = false;
    for (let child of this._children) {
      if (child.hasSubLayer(layer)) {
        res = true;
        break;
      }
    }
    // maybe we are this layer
    if (layer.id === this.id) {
      return true;
    } else {
      return res;
    }
  }

  /**
   * Recurses through all children to find Layer with this id.
   * @param id
   */
  public getLayerById(id: number): Layer | null {
    if (this.id === id) {
      return this;
    } else {
      for (let child of this._children) {
        const res = child.getLayerById(id);
        if (res) {
          return res;
        }
      }
    }
    return null;
  }

  /**
   * Recursively convert this Layer and all children into a representation that ExtJS can process.
   */
  public createTreeNode(curLevel: number, iconClsVisible: string, iconClsInvisible): LayerTreeNodeConfig {
    return {
      text: this._name,
      expanded: curLevel < 2,
      children: <any>this._children.map((oldChild) => {
        return oldChild.createTreeNode(curLevel + 1, iconClsVisible, iconClsInvisible)
      }),
      leaf: !this._group,
      layer: this,
      registered: false,
      checked: this._isRoot ? undefined : this.visible, // undefined: no checkbox is drawn by ExtJS
      iconCls: this.visible ? iconClsVisible : iconClsInvisible
    };
  }

  /**
   * Fetches the GeoJSON feature collection string for all active features on this Layer (and in the given level feature).
   * @param levelFeature
   */
  public fetchFeatureCollection(levelFeature: LevelFeature): Promise<FeatureCollection | null> {
    return this._api.getFeatures({
      layerId: this.id,
      levelFeatureGUID: levelFeature.feature.guid,
      levelLayerId: levelFeature.feature.layer.id,
      levelId: levelFeature.level.id
    }).then(fcResult => {
      if (fcResult && fcResult.data) {
        return fcResult.data;
      } else {
        return null;
      }
    });
  }

  public getFeatureImageURL(feature: FI): string {
    const visColumn = this._visColumn;
    if (!visColumn) {
      console.error('NO vis column?');
      // return default image?
      return '';
    }

    // get value of the specified attribute name
    const attributeName = visColumn.attributeName;
    const value = feature.getRawAttributeValue(attributeName);
    if (!value) {
      console.error(`attribute ${attributeName} does not exist or is null`);
      return '';
    }

    // try to look up this value in the option list
    const image = visColumn.lookupOption(value);
    if (image) {
      return image.url
    } else {
      console.error(`error trying to lookup image for value ${value}`);
      return '';
    }
  }

  public getFeatureImageRawValue(feature: FI): string {
    const visColumn = this._visColumn;
    if (!visColumn) {
      console.error('NO vis column?');
      // return default image?
      return '';
    }

    // get value of the specified attribute name
    const attributeName = visColumn.attributeName;
    const value = feature.getRawAttributeValue(attributeName);
    if (!value) {
      console.error(`attribute ${attributeName} does not exist or is null`);
      return '';
    }
    return value;
  }

  public getFeatureImageName(feature: FI): string {
    const visColumn = this._visColumn;
    if (!visColumn) {
      console.error('NO vis column?');
      // return default image?
      return '';
    }

    // get value of the specified attribute name
    const attributeName = visColumn.attributeName;
    const value = feature.getAttributeValue(attributeName);
    if (!value) {
      console.error(`attribute ${attributeName} does not exist or is null`);
      return '';
    }
    return value;
  }

  public setLastOpenFIGroup(fiGroup: FIGroupInfo | null): void {
    this.lastOpenFIGroup = fiGroup;
  }

  public isLastOpenFIGroup(fiGroup: FIGroupInfo): boolean {
    return this.lastOpenFIGroup === fiGroup;
  }

}
