import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../../igis-base";
import {COMP_IDS, pathIconPictureDisabled, pathIconPictureEnabled} from "../GUIDefinitions";
import {FeatureDataWindow} from "./FeatureDataWindow";
import {lastValueFrom} from "rxjs";
import {ExtButton} from "../../../ext-types/button/ExtButton";
import {first} from "rxjs/operators";
import {Feature} from "@igis-common/model/Feature";

export class FeatureDataWindowComponent extends Component {

  protected windowButton: ExtButton;

  protected dlg: FeatureDataWindow;

  protected curFeature: Feature;

  constructor(protected app: IGIS) {
    super(app);
  }

  init() {
    this.app.gui.gui$.pipe(first()).subscribe(() => {
      // save reference to the window button
      this.windowButton = <ExtButton>this.app.gui.Ext.getCmp(COMP_IDS.FEATURE_WINDOW);
      this.windowButton.setHandler(this.onButtonClick, this);

      this.createWindow();

      this.app.feature.updateSelectedFeature$.subscribe((feature) => {
        this.onNewFeature(feature);
      });
    })
  }

  protected async createWindow() {
    const projInfo = await lastValueFrom(this.app.projectInfo$);
    this.dlg = new FeatureDataWindow(this.app, projInfo);
  }

  public onButtonClick() {
    this.dlg.show();
  }

  protected onNewFeature(feature: Feature | null) {
    if (feature) {
      this.curFeature = feature;
      const featureEntry = feature.extFI;
      if (featureEntry.files.length > 0 || featureEntry.dataEntries.length > 0) {
        this.windowButton.setDisabled(false);
        this.windowButton.setIcon(pathIconPictureEnabled);
      } else {
        this.windowButton.setDisabled(true);
        this.windowButton.setIcon(pathIconPictureDisabled);
      }
    } else {
      this.windowButton.setDisabled(true);
      this.windowButton.setIcon(pathIconPictureDisabled);
    }
  }
}
