import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {COMP_IDS, pathIconCloseBatchDisabled, pathIconCloseBatchEnabled, pathIconPrintReport} from "./GUIDefinitions";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {ExtToolbarTextItem} from "../../ext-types/toolbar/ExtToolbarTextItem";
import {IExt} from "../../ext-types/Ext";
import {DataSet} from "@igis-common/model/DataSet";
import {ExtMenuConfig} from "../../ext-types/menu/ExtMenu";
import {ExtMenuItemConfig} from "../../ext-types/menu/ExtMenuItem";

export class CloseBatchComponent extends Component {

  protected closeBatchButton: ExtButton;
  protected openCntField: ExtToolbarTextItem;

  protected projInfo: ProjectInfo;
  protected Ext: IExt;

  protected curDataSet: DataSet | null;


  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.startup$.subscribe((vals) => {
      const Ext = vals.Ext;
      this.Ext = Ext;
      const projectInfo = vals.projectInfo;

      this.projInfo = projectInfo;

      this.closeBatchButton = Ext.getCmp(COMP_IDS.DATA_ENTRY.CLOSE_BATCH);

      this.openCntField = <ExtToolbarTextItem>Ext.getCmp(COMP_IDS.DATA_ENTRY.OPEN_CNT);

      // listen for change events in the data entry open cnt
      projectInfo.dataSetOpenCnt$.subscribe((openCnt) => {
        this.onNewOpenCnt(openCnt);
      })

      this.app.feature.selectDataSet$.subscribe(selDataSet => {
        this.curDataSet = selDataSet;
        this.updateOpenCnt();
      })
    })
  }

  protected updateOpenCnt() {
    if (this.curDataSet) {
      const openCnt = this.curDataSet.openDataEntryCount;
      if (openCnt > 0) {
        this.openCntField.setHtml('in Bearbeitung: ' + openCnt);
        return;
      }
    }
    this.openCntField.setHtml('');
  }

  protected onNewOpenCnt(openCnt: number) {
    this.updateOpenCnt();
    if (openCnt == 0) {
      // disable button
      this.closeBatchButton.setDisabled(true);
      this.closeBatchButton.setTooltip('Erfassungsvorgang abschließen');
      this.closeBatchButton.setIcon(pathIconCloseBatchDisabled);
      this.closeBatchButton.setMenu(false);
      return;
    }
    // else:
    this.closeBatchButton.setDisabled(false);
    this.closeBatchButton.setTooltip('Erfassungsvorgang abschließen');
    this.closeBatchButton.setIcon(pathIconCloseBatchEnabled);

    // delete old menu
    this.closeBatchButton.setMenu(false);

    let menuConfig: ExtMenuConfig;
    let menuItems: any[] = [];

    let dataSets = this.projInfo.getFeatureDataSets();

    for (let dataSet of dataSets) {
      if (!dataSet.openDataEntryCount) {
        continue;
      }
      // check permission
      const hasPerm = dataSet.hasPermission();
      let setName = dataSet.name;
      // create a new menu entry
      let menuItem: ExtMenuItemConfig = {
        xtype: 'menuitem',
        disabled: !hasPerm,
        text: setName + " (" + dataSet.openDataEntryCount + ")",
        handler: (item, event) => {
          this.onCloseClick(dataSet);
        }
      }
      menuItems.push(menuItem);
    }

    menuConfig = {
      items: menuItems
    }

    this.closeBatchButton.setMenu(menuConfig);
  }

  protected onCloseClick(dataSet: DataSet): void {
    if (this.projInfo) {
      this.Ext.Msg.confirm('Abschließen', 'Erfassungsvorgang für ' + dataSet.name + ' abschließen?', (buttonId) => {
        if (buttonId == 'yes') {
          if (dataSet.openDataEntryCount > 0) {
            this.app.api.finishBatch({
              dataSetId: dataSet.id,
              layerId: dataSet.layers[0].id
            });
          }
        }
      })

    }
  }
}
