
import {DataFieldValue} from '../DataFieldValue';
import {StorageEntry} from '../StorageEntry';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class DataEntryGen extends Model {
  protected _id: number;
  protected _name: string;
  protected _dataSetId: number;
  protected _files: StorageEntry[];
  protected _fieldValues: DataFieldValue[];

  constructor(json: {id: number, name: string, dataSetId: number, files: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean}[], fieldValues: {name: string, value: string, orderNr: number, attribute: {name: string, displayName: string, info: string, unit: string, digits: number, type: number, searchable: boolean, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean}}[]}}[]}, public _api: IGISApi) {
    super();
    this._id = json.id;
    this._name = json.name;
    this._dataSetId = json.dataSetId;
    this._files =  json.files.map(item => new StorageEntry(item, _api));
    this._fieldValues =  json.fieldValues.map(item => new DataFieldValue(item, _api));
    this.onNew();
  }
}
