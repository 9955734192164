import {Component} from "@igis-common/component/Component";
import {FI} from "@igis-common/model/FI";
import {IGIS} from "../../igis-base";
import {IExt} from "../../ext-types/Ext";
import {first} from "rxjs/operators";
import {COMP_IDS, pathIconDelGeometryDisabled, pathIconDelGeometryEnabled} from "../gui/GUIDefinitions";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {IGISConst} from "@igis-common/const";
import {Feature} from "@igis-common/model/Feature";


export class DeleteFeatureComponent extends Component {

  protected Ext: IExt;
  protected buttonDelFeature: ExtButton;
  protected curFeature: Feature | null;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe(Ext => {
      this.Ext = Ext;

      this.buttonDelFeature = Ext.getCmp<ExtButton>(COMP_IDS.GEOMETRY.DEL);
      this.buttonDelFeature.setHandler(this.onButtonClick, this);

      this.app.feature.updateSelectedFeature$.subscribe((feature) => {
        let enabled = false;
        this.curFeature = feature;
        if (feature) {
          if (feature.layer.hasPermission(IGISConst.PERM_DEL_GEOM)) {
            enabled = true;
          }
        }
        if (enabled) {
          this.buttonDelFeature.setDisabled(false);
          this.buttonDelFeature.setIcon(pathIconDelGeometryEnabled);
        } else {
          this.buttonDelFeature.setDisabled(true);
          this.buttonDelFeature.setIcon(pathIconDelGeometryDisabled);
        }
      })
    })
  }

  protected onButtonClick(): void {
    // show a confirmation dialog
    this.Ext.Msg.confirm('Löschen', 'Objekt wirklich löschen?', (id) => {
      if (id === 'yes') {
        const rpcParams = this.curFeature?.getFeatureIdParams();
        if (rpcParams) {
          this.app.api.deleteFeature(rpcParams).then((result) => {
            this.app.feature.clearSelectedFeature();
          });
        }
      }
    }, this);
  }
}


