// Override Ext.button.Button to preserve the tooltip over button enable/disable actions
// hint: we need to do this in js instead of ts, otherwise the ts compiler fucks up "this"
window['Ext'] = require('ExtBase'); // we need to put this in global namespace, otherwise this shitty framework does not work...
/*Ext.define("Ext.fixedTooltip.Button", {
    override: 'Ext.button.Button',
    savedTooltip: "",

    initComponent: function () {
        this.savedTooltip = this.tooltip;
        this.callParent(arguments);
    },

    setTooltip: function (tooltip) {
        this.savedTooltip = tooltip;
        this.callParent(arguments);
    },

    setDisabled: function (disabled) {
        this.callParent(arguments);
        this.setTooltip(this.savedTooltip);
        return this;
    }
});*/

// some translations
Ext.define("Ext.locale.de.window.Window", {
    override: "Ext.window.Window",
    closeToolText: 'Schließen'
});
